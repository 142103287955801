<template>
<div class="m-page">
    <!-- <Header custom-active="0"></Header> -->
    <userHeader custom-active="3"></userHeader>
    <div class="body">
        <div class="login-box">
            <el-form>
                <div class="inner">
                    <div class="hd">
                        <div class="title"> {{ $t("lang.Login") }}</div>
                        <div class="description">
                            {{ $t("lang.Welcome") }}<br />
                            {{ $t("lang.Register") }}
                        </div>
                    </div>
                    <div class="bd" v-if="!is2FA">
                        <el-form-item class="form-item" :label="$t('lang.EmailName')">
                            <el-input v-model="form.account" :placeholder="$t('lang.textEmailAddress')" />
                        </el-form-item>
                        <div class="bd" v-if="isDoubleCheck" >
                            <el-form-item class="form-item" :label="$t('lang.Email')">
                                <el-input v-model="email" :placeholder="$t('lang.PleaseEmailName')" />
                            </el-form-item>
                            <div class="bd"  v-loading="twoFALoading" element-loading-spinner="el-icon-loading" >
                                <el-form-item class="form-item" label="">
                                    <el-input v-model="form.verifyCode" :placeholder="$t('lang.verifyCodePlaceholder')">
                                        <template slot="append">
                                            <el-button type="primary"  @click="seedEmail(email)">{{ $t('lang.sendVerifyCode') }}</el-button>
                                        </template>
                                    </el-input>
                                    <div class="help">
                                        <p>{{ $t("lang.verification") }} <span class="mail">{{sendMail}}</span>. </p>
                                        <p>{{ $t("lang.receive") }} <span class="link" @click="seedEmail(email)">{{ $t("lang.Resend") }}</span></p>
                                    </div>
                                </el-form-item>
                            </div>
                    
                        </div>
                         <span class="forPaw" @click="forgetPawer">{{ $t("lang.ForgotPassword") }}</span>

                        <el-form-item class="form-item" :label="$t('lang.password')">
                            <el-input v-model="form.password" show-password :placeholder="$t('lang.passwordPlaceholder')" />
                        </el-form-item>
                        <!-- <el-form-item class="form-item">
                            <el-checkbox>I agree to the terms of service </el-checkbox>
                        </el-form-item> -->
                        <el-form-item class="form-item" v-if="isDoubleCheck">
                            <el-button type="primary" class="btn-login" :loading="loginLoading" :disabled="loginIsDisabled" @click="loginAgain">{{ $t("lang.Login") }}</el-button>
                        </el-form-item>
                        <el-form-item class="form-item" v-else>
                            <el-button type="primary" class="btn-login" :loading="loginLoading" :disabled="loginIsDisabled" @click="login">{{ $t("lang.Login") }}</el-button>
                        </el-form-item>
                    </div>
                    <div class="bd" v-else >
                        <el-form-item class="form-item" :label="$t('lang.Email')">
                            <el-input v-model="email" :placeholder="$t('lang.PleaseEmailName')" />
                        </el-form-item>
                        <div class="bd"  v-loading="twoFALoading" element-loading-spinner="el-icon-loading" >
                            <el-form-item class="form-item" label="">
                                <el-input v-model="verifyCode" :placeholder="$t('lang.verifyCodePlaceholder')">
                                    <template slot="append">
                                        <el-button type="primary"  @click="seedEmail(email)">{{ $t('lang.sendVerifyCode') }}</el-button>
                                    </template>
                                </el-input>
                                <div class="help">
                                    <p>{{ $t("lang.verification") }} <span class="mail">{{sendMail}}</span>. </p>
                                    <p>{{ $t("lang.receive") }} <span class="link" @click="seedEmail(email)">{{ $t("lang.Resend") }}</span></p>
                                </div>
                            </el-form-item>
                        </div>
                    
                        <el-form-item class="form-item" :label="$t('lang.NewPassword')">
                            <el-input v-model="password" show-password :placeholder="$t('lang.newPassword')" />
                        </el-form-item>
                        <el-form-item class="form-item" :label="$t('lang.confirmPassword')" prop="checkPassword">
                          <el-input v-model="checkPassword" show-password :placeholder="$t('lang.confirmPasswordPlaceholder')" />
                        </el-form-item>
                        <el-form-item class="form-item">
                            <el-button type="primary" class="btn-login" :loading="loginLoading" @click="possWordEdit">{{ $t("lang.ResetPassword") }}</el-button>
                        </el-form-item>
                    </div>
                    <div class="ft">
                        <div class="p">
                            {{ $t("lang.NeedAccount") }} <a class="link" @click="goto('/signup')">{{ $t("lang.SignUp") }}</a>
                        </div>
                    </div>
                </div>
            </el-form>
        </div>
    </div>
    <Footer></Footer>
</div>  
</template>
<script>
import Header from "@/components/mobile/header";
import userHeader from "@/components/mobile/userHeader";
import Footer from "@/components/mobile/footer";
import {BASE_GLOB_API} from "@/config.js";
import {axiosGet, axiosPost} from '@/api/api';
export default {
    name:'login-m',
    data(){
        return {
            form:{
                account:'',
                password:'',
                verifyCode:'',
            },
            is2FA:false,
            loginLoading:false,
            twoFALoading:false,
            verifyLoading:false,
            sendMail:'',
            url:{
                login:BASE_GLOB_API+'/login/email',
                sendCode:BASE_GLOB_API+'/register/verifyCode/send',
                tempId:BASE_GLOB_API+'/user/tempId',
                edit:BASE_GLOB_API+'/register/resetPassword',
                platform:BASE_GLOB_API+'/kanban/coin/platform',
                userinfo:BASE_GLOB_API+'/kanban/wallet/info',
            },
            returnUrl:'',
            lang: "",
            email:'',
            verifyCode:'',
            password:'',
            checkPassword: '',
            isDoubleCheck:false,
        }
    },
    components: {
        Header,
        Footer,
        userHeader
    },
    computed:{
        loginIsDisabled:function(){
            let flag = false;
            if(this.form.account.length>0 && this.form.password.length>0){
                flag = true;
            }
            return !flag;
        }
    },
    created(){
        this.lang = sessionStorage.getItem("Language")
    },
    mounted(){
        // console.log("thi",this.$route.query)
        // if(this.$route.query){
        //     this.returnUrl = this.$route.query.redirect;
        // }
    },
    methods:{
        restBt(){
            this.form.account =''
            this.form.password =''
            this.form.verifyCode =''
        },
        possWordEdit(){
          if(!this.email){
            this.$message.error(this.$t("lang.textEmailAddress"));
            return;
          }
          if(!this.verifyCode){
            this.$message.error(this.$t("lang.textVerifyCode"));
            return;
          }
          if(!this.password){
            this.$message.error(this.$t("lang.newPassword"));
            return;
          }
          if(this.password !== this.checkPassword){
            this.$message.error(this.$t("lang.PasswordText"));
            return;
          }
            let params = {
                // account:this.userInfo.account,
                email:this.email,
                verifyCode:this.verifyCode,
                password:this.password,
            }
            axiosPost(this.url.edit, params).then(res=>{
                if(res.success){
                    this.verifyCode ='';
                    this.password ='';
                    this.$router.push({name:'login-m'});
                    this.$message.success(res.message);
                    this.is2FA =false
                }else{
                    this.$message.error(res.message);
                }
                
            })
        },
        seedEmail(data){
            if(!this.email){
                this.$message.error(this.$t("lang.textEmailAddress"));
                return;
            }
            this.twoFALoading = true;
            if(data){
                this.sendMail = data.split("@")[0].slice(0,3)+'***@'+data.split("@")[1];
            }else{
                this.sendMail =''
            }
            let params = {
                email:data
            }
            axiosGet(this.url.sendCode, params,60000).then(res=>{
                this.twoFALoading = false;
                if(res.success){
                    this.$message.success(res.message);
                }else{
                    this.$message.error(res.message);
                }
                
            })
        },
        forgetPawer(){
          this.is2FA =true
        },
        goto(url){
            this.$router.push({path:url})
        },
        login(){
            let params = {};
            params = Object.assign(params, this.form);
            this.loginLoading = true;
            axiosPost(this.url.login, params).then(res=>{
                if(res.success){
                    let data = res.result;
                    if(data.doubleCheck){
                        this.isDoubleCheck = true;
                       this.loginLoading = false;
                       return;
                    }else{
                        this.isDoubleCheck = false;
                        window.localStorage.setItem("Token", data.token);
                        window.sessionStorage.setItem("Token", data.token);
                        window.localStorage.setItem('salt', data.salt);
                        let userinfo = {
                            id:data.id,
                            account:data.account,
                            nikeName:data.nikeName,
                            avatar:data.headImg,
                            email:data.email,
                        }
                        window.sessionStorage.setItem("userinfo", JSON.stringify(userinfo));
                        this.loginLoading = false;
                        this.platform()
                        this.restBt()
                        this.$router.push({name:'account-m'});
                        // if(this.returnUrl){
                        //     this.$router.push({path:this.returnUrl, params:{token:data.token}})
                        //     this.gotoTempId(data);
                        // }else{
                        //     this.$router.push({name:'account-m'});
                        // }
                  } 
                }else{
                    this.$message.error(res.message);
                    this.loginLoading = false;
                }
            })
        },
        loginAgain(){
            if(!this.email){
                this.$message.error(this.$t("lang.textEmailAddress"));
                return;
            }
            if(!this.form.verifyCode){
                this.$message.error(this.$t("lang.textVerifyCode"));
                return;
            }
            if(!this.form.password){
                this.$message.error(this.$t("lang.passwordPlaceholder"));
                return;
            }
            let params = {};
            params = Object.assign(params, this.form);
            this.loginLoading = true;
            axiosPost(this.url.login, params).then(res=>{
                if(res.success){
                    let data = res.result;
                    this.isDoubleCheck = false;
                    window.localStorage.setItem("Token", data.token);
                    window.sessionStorage.setItem("Token", data.token);
                    window.localStorage.setItem('salt', data.salt);
                    let userinfo = {
                        id:data.id,
                        account:data.account,
                        nikeName:data.nikeName,
                        avatar:data.headImg,
                        email:data.email,
                    }
                    window.sessionStorage.setItem("userinfo", JSON.stringify(userinfo));
                    window.localStorage.setItem("userinfo", JSON.stringify(userinfo));
                    this.loginLoading = false;
                    this.platform()
                    this.restBt()
                    // this.$router.push({name:'account-m'});
                    if(this.returnUrl){
                        this.$router.push({path:this.returnUrl, params:{token:data.token}})
                        // this.gotoTempId(data);
                    }else{
                        this.$router.push({name:'account-m'});
                    }
                }else{
                    this.$message.error(res.message);
                    this.loginLoading = false;
                }
            })
        },
        platform(){
            axiosGet(this.url.platform).then(res=>{
               if(res.code ==200){
                let date =res.result
                this.items = []
                 if(date.length > 0){
                    date.forEach(item=>{
                       if(item.enabled){
                        let source ={
                            enabled:item.enabled,
                            fullName:item.fullName,
                            icon:item.icon,
                            id:item.id,
                            shortName:item.shortName,
                            unit:item.unit,
                            networkVos:item.networkVos,
                            enabledRecharge:item.enabledRecharge,
                            enabledWithdrawal:item.enabledWithdrawal
                        }
                        this.items.push(source)
                      }
                    });
                    this.value =this.items[0].id
                    this.UserInfo(this.items[0].id)
                       
                 }
             
               }else{
                    this.$message.error(res.message);
                }
            })
           
        }, 
        UserInfo(value){
            axiosGet(this.url.userinfo,{btPlatformId:value}).then(res=>{
               if(res.code ==200){
                  window.sessionStorage.setItem("btFull", JSON.stringify(res.result));
               }else{
                    this.$message.error(res.message);
                }
            })
           
        }, 
        gotoTempId(data){

            let params = {
                returnUrl:this.returnUrl
            }
            axiosGet(this.url.tempId, params).then(res=>{
                if(res.success){
                    // window.open("https://"+res.result)
                    window.location.href = res.result;
                }else{
                    this.$message.error(res.message);
                }
                
            })
        },
        twoFAVerify(data){
            this.twoFALoading = true;
            if(data.account){
                this.sendMail = data.account.split("@")[0].slice(0,3)+'***@'+data.account.split("@")[1];
            }else{
                this.sendMail =''
            }
            let params = {
                email:data.account
            }
            axiosGet(this.url.sendCode, params,60000).then(res=>{
                this.twoFALoading = false;
                if(res.success){
                    this.$message.success(res.message);
                }else{
                    this.$message.error(res.message);
                }
                
            })
        },
        verify(){
            this.verifyLoading = true;
            let params = {}
        },
    }
}
</script>
<style lang="less" scoped>
.m-page {
    background:url(~@/assets/img/login_bg.svg) no-repeat center center/2116px 2156px;
    .body {
        padding:20px 30px; min-height:640px; height:calc(100vh - 267px);
        .login-box {
            padding:20px; border-radius:8px; background:#fff; box-shadow:0 0 8px rgba(0,0,0,.08);
            .hd {
                padding:0 0 15px;
                .title {
                    font-size:32px; font-weight:800;
                }
                .description {
                    font-size:16px; color:#999; font-weight:300;
                }
            }
            .bd {
                
                ::v-deep .el-tabs {
                    .el-tabs__header {
                        .el-tabs__item {
                            font-size:16px; text-transform:uppercase; //font-family: 'bebas_neueregular';
                        }
                    }
                }
                .form-item {
                    margin-bottom:15px;
                    ::v-deep .el-form-item__label {
                        font-weight:600; color:#999; line-height:30px;
                    }
                    ::v-deep .el-checkbox {
                        .el-checkbox__label {
                            font-weight:normal; color:#999;
                        }
                    }
                    ::v-deep .el-input-group__append {
                        background:transparent;
                        .el-button {
                            border-radius:0 4px 4px 0; color:#fff;
                            /*&.is-disabled {
                                opacity: .6;
                            }*/
                        }
                    }
                    .help {
                        line-height:1.47; color:#999; padding:5px 0 0;
                        .mail {color:#4458FE;}
                        .link {
                            color:#4458FE; font-weight:700; cursor: pointer;
                            &:hover {
                                opacity: .8;
                            }
                        }
                    }
                    .btn-login {
                        display:block; width:100%;
                    }
                }
                .forPaw{
                       color: #4458FE;
                    }
            }
            .ft {
                padding:20px 0 0; text-align:center;
                .p {
                    color:#666;
                    .link {
                        font-weight:600; color:#4458FE; cursor: pointer;
                        &:hover {
                            text-decoration: underline; opacity:.8;
                        }
                    }
                }
            }
        }
    }
}
</style>